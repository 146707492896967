import React from "react";
import { NavLink } from "react-router-dom";
import { Image, Grid, Header, Button } from "semantic-ui-react";

import { NewsList } from "../Pages/News";

export default function Home() {
  return (
    <>
      <Grid centered stackable>
        <Grid.Column width={4} className="computer only">
          <Image src="/images/lpcrest.png" floated="left" />
        </Grid.Column>
        <Grid.Column width={12}>
          <Header as="h1">Welcome to Lambda Phi!</Header>
          <p>
            This web system is run by the Lambda Phi Corporation for the benefit
            of our members. Please use the menu at the upper left to explore!
          </p>
          <div>
            <Button.Group basic floated="right">
              <Button circular icon="plus" as={NavLink} to="/news/new" />
              <Button circular icon="history" as={NavLink} to="/news/history" />
            </Button.Group>
            <Header as="h2">News</Header>
          </div>
          <NewsList />
        </Grid.Column>
      </Grid>
    </>
  );
}

import React from "react";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";

import "./Campaign.css";
import { DonationElement } from "../Components/Ordering";
import { LoginInfo } from "../Utils/Login";
import { Link, useLocation } from "react-router-dom";

interface CampaignProps {
  loginInfo: LoginInfo;
}
export default function Campaign(props: CampaignProps) {
  return (
    <>
      <Segment className="masthead">
        <Header size="huge">Lambda Phi 2021 Campaign</Header>
      </Segment>
      <Segment vertical>
        <Header size="large">
          Lambda Phi is now the proud owner of 351 Mass Ave!
        </Header>
      </Segment>
      <Segment vertical>
        <Grid stackable>
          <Grid.Column width="8">
            <Header size="small">It has been our home for 45 years...</Header>
            <div className="row1image yearbookimage">1981</div>
          </Grid.Column>
          <Grid.Column width="8">
            <Header size="small">
              ... but age has indeed "left its sad scar."
            </Header>
            <div className="row1image lamsonimage">2021</div>
          </Grid.Column>
        </Grid>
      </Segment>

      <Segment vertical>
        <DonationFlow loginInfo={props.loginInfo}></DonationFlow>
      </Segment>
      <Segment vertical>
        <Grid stackable>
          <Grid.Column width="12">
            <Header size="medium">Your chapter needs your help!</Header>
            <p>
              Many of you donated to our 2014 campaign. That campaign, combined
              with our other sources of funding, enabled two major projects:
              renovating all of the chapter house’s 18 bathrooms as well as the
              key public areas on the first and second floors.
            </p>
            <p>
              Since then, our alumni leadership has worked to prepare for our
              next major phase of work: repointing the crumbling brick and
              mortar on our entire facade, and replacing our 1906-era windows.
              Our chapter needs to raise an additional $650,000 to complete our
              remaining work.
            </p>
            <p>
              <strong>This work needs your support!</strong>
            </p>
          </Grid.Column>
          <Grid.Column width="4">
            <Segment color="green">
              <Header size="medium">Double your impact!</Header>
              <p>
                Several of our alumni have generously volunteered to match up to
                $225,000 of your donations!
              </p>
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment vertical>
        <Header size="medium">Have more questions?</Header>
        <p>
          Contact us at{" "}
          <a href="mailto:2021@lambdaphi.org">2021@lambdaphi.org</a>
        </p>
      </Segment>
    </>
  );
}

interface DonationProps {
  loginInfo: LoginInfo;
}

function DonationFlow(props: DonationProps) {
  const [step, setStep] = React.useState(0);
  const [wantTaxDeductible, setWantTaxDeductible] = React.useState(false);
  let location = useLocation();

  let content = <></>;

  switch (step) {
    case 0:
      content = (
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              setStep(1);
            }}
            primary
            size="large"
          >
            How can I donate?
          </Button>
        </div>
      );
      break;

    case 1:
      content = (
        <>
          <Header size="small">
            Do you itemize deductions on your US federal income taxes?
          </Header>
          <Button
            onClick={() => {
              setWantTaxDeductible(true);
              setStep(2);
            }}
            size="large"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setWantTaxDeductible(false);
              setStep(2);
            }}
            size="large"
          >
            No
          </Button>
        </>
      );
      break;

    case 2:
      if (wantTaxDeductible) {
        content = (
          <>
            <Header size="medium">Donate via MIT IRDF</Header>
            <p>
              The <b>MIT Independent Residence Development Fund</b> supports MIT
              living groups through grants and low-interest loans. Our
              rennovation work has been approved by the IRDF board as a historic
              preservation grant.
            </p>
            <p>
              When you donate to MIT IRDF,{" "}
              <b>
                90% of the amount of your donation will be sent to the Lambda
                Phi Chapter
              </b>
              , and the remaining 10% will be used for IRDF's other projects
              supporting our chapter and other MIT living groups.
            </p>
            <p>
              To donate:
              <ol>
                <li>
                  Donate to MIT via{" "}
                  <a href="https://giving.mit.edu/check">check</a> or{" "}
                  <a href="https://giving.mit.edu/search/node/irdf">online</a>,
                  and designate your gift to IRDF.
                </li>
                <li>
                  Let us know at{" "}
                  <a href="mailto:2021@lambdaphi.org">2021@lambdaphi.org</a> so
                  that we can ensure your gift is properly processed.
                </li>
              </ol>
            </p>
            <p>
              If you would like an alternative to MIT for tax-deductible
              donations, please contact us.
            </p>
          </>
        );
      } else {
        content = (
          <>
            <Header size="medium">Donate directly to the fraternity</Header>
            <p>
              Donations made directly to the fraternity are not tax deductible,
              but allow nearly your entire donation to benefit our work.
            </p>
            <Header size="small">Donate via check</Header>
            <p>
              <b>Your entire donation goes to Lambda Phi</b>, less ~$3
              processing costs.
            </p>
            <strong>Mail your donation to:</strong>
            <p>
              <div>Lambda Phi of Alpha Delta Phi</div>
              <div>Attn: Donations</div>
              <div>PO Box 390528</div>
              <div>Cambridge, MA 02139-9835</div>
            </p>
            <Header size="small">Donate via credit card</Header>
            <p>
              <b>98.1% of your donation goes to Lambda Phi</b>, with the
              remainder covering credit card fees.
            </p>
            <Button
              primary
              size="large"
              onClick={(event) => {
                event.preventDefault();
                setStep(3);
              }}
            >
              Donate online
            </Button>
          </>
        );
      }
      break;

    case 3:
      if (props.loginInfo.id == null) {
        let destination = encodeURIComponent(
          location.pathname + location.search
        );

        content = (
          <>
            Please <Link to={"/?dest=" + destination}>log in</Link> in order to
            donate online.
          </>
        );
      } else {
        content = <DonationElement loginInfo={props.loginInfo} />;
      }

      break;
  }

  return (
    <>
      {content}
      {step < 2 ? (
        <></>
      ) : (
        <>
          <Divider />
          <div>
            <Button
              onClick={(event) => {
                event.preventDefault();
                setStep(1);
              }}
              as="a"
            >
              start over
            </Button>
          </div>
        </>
      )}
    </>
  );
}

import axios, { AxiosResponse, AxiosError } from "axios";

export interface NewsItem {
  id: number;
  headline: string;
  text: string;
  start_time: string;
  end_time: string;
  chapter: string;
  created_by_usr_id: number;
  created_time: string;
}

export function GetNews(
  callback: (statusCode: number, input: NewsItem[]) => void
) {
  axios({
    method: "get",
    url: "/api/currentnews",
  })
    .then(function (response: AxiosResponse) {
      callback(response.status, response.data.news_items as NewsItem[]);
    })
    .catch(function (error: AxiosError) {
      callback(error.response?.status || 0, {} as NewsItem[]);
    });
}

import React, { MouseEvent } from "react";
import { NavLink, Redirect, useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Image,
} from "semantic-ui-react";
import {
  LoginInfo,
  APILogin,
  APIRequestPasswordReset,
  APIResetPassword,
} from "../Utils/Login";

type LoginProps = {
  setLoginInfo: (input: LoginInfo) => void;
};

export default function LoginDialog(props: LoginProps) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);

  function handleLogin(event: MouseEvent) {
    event.preventDefault();
    APILogin(email, password, (statusCode: number, input: LoginInfo): void => {
      if (statusCode === 200) {
        props.setLoginInfo(input);

        // if dest param is set, go there
        let destParam = query.get("dest");
        if (destParam != null) {
          history.push(destParam);
        }
      } else {
        if (statusCode === 401) {
          setErrorMessage("Invalid email and password combination.");
        } else {
          setErrorMessage("An error occurred, please try again.");
        }
      }
    });
  }

  let messageElement: JSX.Element = <></>;
  if (errorMessage) {
    messageElement = (
      <Message negative>
        <div>{errorMessage}</div>
      </Message>
    );
  }

  function handleInputChange(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;

    switch (target.name) {
      case "email":
        setEmail(target.value);
        break;
      case "password":
        setPassword(target.value);
        break;
      default:
        break;
    }

    setErrorMessage("");
  }

  return (
    <Grid centered stackable>
      <Grid.Column width={10}>
        <Image src="/images/lpcrest.png" floated="left" />
        <h1>Welcome</h1>
        <p>
          Welcome! This is a private website for the members of the alumni
          corporation of the Lambda Phi Chapter of the Alpha Delta Phi
          Society.
        </p>
      </Grid.Column>
      <Grid.Column width={6}>
        <Header as="h2" textAlign="center">
          Login
        </Header>
        {messageElement}
        <Segment>
          <Form size="large">
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Email address"
              name="email"
              onChange={handleInputChange}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              name="password"
              type="password"
              onChange={handleInputChange}
            />

            <Button primary fluid size="large" onClick={handleLogin}>
              Login
            </Button>
          </Form>
        </Segment>
        <Message>
          <div>
            Can't log in? <NavLink to="/help">Get Help</NavLink>
          </div>
        </Message>
      </Grid.Column>
    </Grid>
  );
}

export function LoginHelp() {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState(<></>);

  function handleInputChange(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;

    switch (target.name) {
      case "email":
        setEmail(target.value);
        break;
      default:
        break;
    }

    setMessage(<></>);
  }

  function handlePasswordResetRequest(event: MouseEvent) {
    event.preventDefault();

    APIRequestPasswordReset(email, (statusCode: number): void => {
      if (statusCode === 200) {
        setMessage(
          <Message positive>
            If there was an email address on file for {email}, we sent you
            information on how to reset your password.
          </Message>
        );
      } else {
        setMessage(
          <Message negative>
            There was an error attempting to send the password reset email.
          </Message>
        );
      }
    });
  }

  return (
    <Grid centered stackable>
      <Grid.Column width={10}>
        <h1>Need help?</h1>
        <p>
          If you've forgotten your password (or never had one in the first
          place) but you're on our e-mail mailing lists, use the form below.
          Make sure you enter the email address where you receive mail to our
          mailing lists.
        </p>
        <p>
          If this doesn't work or if we don't have your email address, write to{" "}
          <strong>help (at) lambdaphi.org</strong> with your name, email
          address, and pledge class name and we'll set you up.
        </p>
        <h2>Reset your password</h2>
        {message}
        <Segment>
          <Form size="large">
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Email address"
              name="email"
              onChange={handleInputChange}
            />
            <Button
              primary
              fluid
              size="large"
              onClick={handlePasswordResetRequest}
            >
              Request Password Reset
            </Button>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}

export function ResetPassword() {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [message, setMessage] = React.useState(<></>);
  let query = new URLSearchParams(useLocation().search);

  if (!(query.get("id") && query.get("ver"))) {
    return <Redirect to="/help" />;
  }

  function handleInputChange(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;

    switch (target.name) {
      case "password":
        setPassword(target.value);
        break;
      case "password2":
        setConfirmPassword(target.value);
        break;
      default:
        break;
    }

    setMessage(<></>);
  }

  function handlePasswordReset(event: MouseEvent) {
    event.preventDefault();

    if (password !== confirmPassword) {
      setMessage(
        <Message negative>
          The two passwords you entered do not match. Please try again.
        </Message>
      );
      return;
    }

    APIResetPassword(
      parseInt(query.get("id") || ""),
      query.get("ver") || "",
      password,
      (statusCode: number): void => {
        if (statusCode === 200) {
          setMessage(
            <Message positive>Password updated successfully.</Message>
          );
        } else if (statusCode === 401) {
          setMessage(
            <Message negative>
              You appear to have followed an invalid or expired link, please
              return to the <NavLink to="/help">help page</NavLink> and try
              again.
            </Message>
          );
        } else {
          setMessage(
            <Message negative>
              There was an error attempting to reset your password.
            </Message>
          );
        }
      }
    );
  }

  return (
    <Grid centered stackable>
      <Grid.Column width={10}>
        <h2>Reset your password</h2>
        {message}
        <Segment>
          <Form size="large">
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="New password"
              name="password"
              type="password"
              onChange={handleInputChange}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Confirm new password"
              name="password2"
              type="password"
              onChange={handleInputChange}
            />
            <Button primary fluid size="large" onClick={handlePasswordReset}>
              Reset Password
            </Button>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}

import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { LoginInfo } from "../Utils/Login";

interface LegacyFrameProps {
  path: string;
  loginInfo: LoginInfo;
  children?: React.ReactNode;
}

export default function PrivateRoute(props: LegacyFrameProps) {
  let location = useLocation();
  let destination = encodeURIComponent(location.pathname + location.search);

  return (
    <Route
      path={props.path}
      render={(innerprops): React.ReactNode => {
        if (props.loginInfo.id) {
          return props.children;
        }

        return <Redirect to={"/?dest=" + destination} />;
      }}
    />
  );
}

import React, { MouseEvent } from "react";
import {
  Button,
  Dimmer,
  Form,
  Loader,
  Message,
  Header,
} from "semantic-ui-react";
import {
  GetPaymentIntentPromise,
  GetPublishableSecretPromise,
} from "../Utils/Ordering";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { LoginInfo } from "../Utils/Login";
import { APIError } from "../Utils/API";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = getStripePromise();

function getStripePromise(): Promise<Stripe | null> {
  return GetPublishableSecretPromise().then((result) => {
    return loadStripe(result);
  });
}

interface DonationElementProps {
  loginInfo: LoginInfo;
}
export function DonationElement(props: DonationElementProps) {
  const [amountString, setAmountString] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [paymentClientSecret, setPaymentClientSecret] = React.useState("");
  const [workflowStep, setWorkflowStep] = React.useState("initial");

  function handleAmountSelection(event: MouseEvent) {
    event.preventDefault();

    let regexp = new RegExp("^[1-9]\\d*(\\.\\d\\d)?$");
    if (regexp.test(amountString)) {
      setAmount(Number(amountString));
      GetPaymentIntentPromise(amountString)
        .then((result) => {
          setLoading(false);
          setPaymentClientSecret(result);
          setWorkflowStep("payment");
          return;
        })
        .catch((err) => {
          // error
          setLoading(false);
          setErrorMessage(
            err.message
              ? err.message
              : "An error occurred while loading the page."
          );
        });

      setLoading(true);
      setErrorMessage("");
    } else {
      setErrorMessage("Please enter a positive integer dollar amount.");
    }
  }

  function handleInputChange(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;

    switch (target.name) {
      case "amount":
        setAmountString(target.value);
        break;

      default:
        break;
    }

    setErrorMessage("");
  }

  function handlePaymentComplete(paymentCompleted: boolean) {
    setWorkflowStep(paymentCompleted ? "completed" : "in progress");
  }

  let messageElement: JSX.Element = <></>;
  if (errorMessage) {
    messageElement = (
      <Message negative>
        <div>{errorMessage}</div>
      </Message>
    );
  }

  let amountForm = (
    <Form size="large">
      <Form.Input
        fluid
        icon="dollar sign"
        iconPosition="left"
        placeholder="0"
        name="amount"
        value={amountString}
        onChange={handleInputChange}
      />

      <Button primary fluid size="large" onClick={handleAmountSelection}>
        Continue
      </Button>
    </Form>
  );

  let confirmationForm = (
    <>
      <Header size="medium">Donation complete</Header>
      Thank you for your donation of ${amount.toFixed(2)}! Your donation is
      complete.
    </>
  );

  let confirmationInProgressForm = (
    <>
      <Header size="medium">Donation processing</Header>
      Thank you for your donation of ${amount.toFixed(2)}! Your donation is
      processing; we'll reach out if there are any issues.
    </>
  );

  let chosenForm = <></>;

  switch (workflowStep) {
    case "initial":
      chosenForm = amountForm;
      break;

    case "payment":
      chosenForm = (
        <>
          <h4>Donation of ${amount.toFixed(2)}</h4>
          <Elements stripe={stripePromise}>
            <DonationCheckoutElement
              loginInfo={props.loginInfo}
              setLoading={setLoading}
              setErrorMessage={setErrorMessage}
              clientSecret={paymentClientSecret}
              amount={amount}
              checkoutCompleted={handlePaymentComplete}
            />
          </Elements>
        </>
      );
      break;

    case "completed":
      chosenForm = confirmationForm;
      break;

    case "in progress":
      chosenForm = confirmationInProgressForm;
      break;
  }

  return (
    <>
      <>
        {messageElement}
        <Dimmer active={loading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        {chosenForm}
        <p style={{ margin: "1em 0 0 0" }}>
          Please note that as the fraternity is a 501(c)7 organization,
          individual donations made directly to the fraternity are not tax
          deductible.
        </p>
      </>
    </>
  );
}

interface DonationCheckoutElementProps {
  amount: number;
  clientSecret: string;
  loginInfo: LoginInfo;
  setLoading: (val: boolean) => void;
  setErrorMessage: (val: string) => void;
  checkoutCompleted: (completed: boolean) => void;
}

function DonationCheckoutElement(props: DonationCheckoutElementProps) {
  const stripe = useStripe();
  const elements = useElements();

  function handleDonateAction(event: MouseEvent) {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let card = elements.getElement(CardElement);
    if (card === null) {
      return;
    }

    stripe
      .confirmCardPayment(props.clientSecret, {
        payment_method: {
          card: card,
          billing_details: {
            name: props.loginInfo.first_name + " " + props.loginInfo.last_name,
          },
        },
      })
      .then((result) => {
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          return Promise.reject({
            debug:
              "error while processing Stripe payment " + result.error.message,
            message: "An error occurred while processing your payment.",
          } as APIError);
        } else {
          // The payment has been processed!
          switch (result.paymentIntent.status) {
            case "succeeded":
              props.checkoutCompleted(true);
              break;

            case "requires_payment_method":
              return Promise.reject({
                debug:
                  "declined or other issue while processing Stripe payment: " +
                  result.paymentIntent.description,
                message:
                  "Your card was declined or had another issue being processed.",
              } as APIError);

            case "processing":
              props.checkoutCompleted(false);

              break;

            default:
              break;
          }
        }
        props.setLoading(false);
      })
      .catch((err) => {
        // error
        props.setLoading(false);
        props.setErrorMessage(
          err.message
            ? err.message
            : "An error occurred while loading the page."
        );
      });

    props.setLoading(true);
    props.setErrorMessage("");
  }

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <>
      <Form>
        <div style={{ margin: "0 0 1em 0" }}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
        <Button
          primary
          fluid
          size="large"
          disabled={!stripe}
          onClick={handleDonateAction}
        >
          Donate
        </Button>
      </Form>
    </>
  );
}

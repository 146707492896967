import React, { useState, useEffect, Fragment } from "react";
import { useRouteMatch } from "react-router";
import { useLocation } from "react-router-dom";
import { Loader, Message } from "semantic-ui-react";

import { LoginInfo, APIGetSSOKey } from "../Utils/Login";

interface LegacyFrameProps {
  route: string;
  loginInfo: LoginInfo;
}

export default function LegacyFrame(props: LegacyFrameProps) {
  const [loading, setLoading] = useState(true);
  const [ssoKey, setSsoKey] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [ssoKeyTimestamp, setSsoKeyTimestamp] = useState(0);

  useEffect(() => {
    if (props.loginInfo.id !== 0 && ssoKey === "") {
      APIGetSSOKey((statusCode: number, key: string) => {
        if (statusCode === 200) {
          setSsoKey(key);
          setSsoKeyTimestamp(Date.now());
          setLoading(false);
          return;
        }

        // error
        setErrorMessage("An error occurred loading page.");
        setLoading(false);
      });
    }

    setLoading(false);
  }, [props.loginInfo.id, ssoKey]);

  var route = "";

  // if sso timestamp is over 30 sec ago, clear it
  if (ssoKeyTimestamp !== 0 && ssoKeyTimestamp + 30000 < Date.now()) {
    setSsoKey("");
    setSsoKeyTimestamp(0);
    setLoading(true);
  }

  if (props.loginInfo.id !== 0 && ssoKey !== "") {
    route = props.loginInfo.legacy_base_url + props.route;
    if (props.route.includes("?")) {
      route = route + "&";
    } else {
      route = route + "?";
    }
    route = route + "SSO_LOGIN=" + ssoKey;
  }

  return (
    <Fragment>
      {loading ? (
        <Loader active />
      ) : errorMessage ? (
        <Message negative>
          <div>{errorMessage}</div>
        </Message>
      ) : (
        <iframe
          className="fluid"
          max-width="100%"
          width="100%"
          height="100%"
          title="legacy frame"
          src={route}
        />
      )}
    </Fragment>
  );
}

interface LegacyPageWrapperProps {
  loginInfo: LoginInfo;
}
export function LegacyPageWrapper(props: LegacyPageWrapperProps) {
  let url = useRouteMatch();
  let search = useLocation().search;
  let newUrl = url.url + search;

  return <LegacyFrame route={newUrl} loginInfo={props.loginInfo} />;
}

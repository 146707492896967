export default function ContactUs() {
  return (
    <>
      <div>
        This page lists general contact information for the lambdaphi.org web
        site, in case you can't find who you're looking for elsewhere. E-mail is
        the preferred and fastest method of getting in touch.
      </div>
      <h1>E-mail</h1>
      <div>
        For fastest reponse, email us at{" "}
        <strong>help (at) lambdaphi.org</strong>
      </div>
      <h1>Phone</h1>
      <div>You may also leave us a voice message at 267-350-6541.</div>
      <h1>Mail</h1>
      <p>
        <div>
          <strong>Written correspondence</strong> should be directed to:{" "}
        </div>
        <div style={{ marginTop: "1em" }}>Lambda Phi Corporation</div>
        <div>PO Box 426097</div>
        <div>Cambridge, MA 02142</div>
      </p>
      <p>
        <div>
          <strong>Donations</strong> should be mailed to:
        </div>
        <div style={{ marginTop: "1em" }}>Lambda Phi of Alpha Delta Phi</div>
        <div>Attn: Donations</div>
        <div>PO Box 390528</div>
        <div>Cambridge, MA 02139-9835</div>
      </p>
      <p>
        <em>
          Note that as we are a 501(c)7, any donations made directly to the
          fraternity are not tax-deductible.
        </em>
      </p>
      <p>
        <div>
          The corporation's <strong>principal place of business</strong> is the
          chapter house:
        </div>
        <div style={{ marginTop: "1em" }}>351 Massachusetts Ave</div>
        <div>Cambridge, MA 02139</div>
      </p>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Header, Placeholder, Message, Segment } from "semantic-ui-react";

import { GetNews, NewsItem } from "../Utils/News";
import { UserName } from "../Components/User";

export function NewsList() {
  const [newsList, setNewsList] = React.useState([] as NewsItem[]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (loading) {
      GetNews((statusCode: number, input: NewsItem[]) => {
        if (statusCode === 200) {
          setLoading(false);
          setNewsList(input);
          return;
        }

        // error
        setLoading(false);
        setErrorMessage("An error occurred loading page.");
      });
    }
  }, [loading]);

  let placeholders = [1, 2, 3].map((value, index) => (
    <Placeholder>
      <Placeholder.Header>
        <Placeholder.Line />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  ));

  return (
    <>
      {loading ? (
        placeholders
      ) : errorMessage ? (
        <Message negative>
          <div>{errorMessage}</div>
        </Message>
      ) : newsList.length === 0 ? (
        <p>No news today!</p>
      ) : (
        newsList.map((value: NewsItem, index: number) => NewsRow(value))
      )}
    </>
  );
}

export function NewsRow(newsItem: NewsItem) {
  let time = Date.parse(newsItem.created_time);

  return (
    <Segment>
      <Header as="h4">{newsItem.headline}</Header>
      <p>{newsItem.text}</p>
      <p>
        <small>
          <UserName id={newsItem.created_by_usr_id} /> on{" "}
          {new Intl.DateTimeFormat("en-US").format(time)}
        </small>
      </p>
    </Segment>
  );
}

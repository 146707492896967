import React, { SyntheticEvent } from "react";
import {
  Dropdown,
  Menu,
  Image,
  Container,
  DropdownItemProps,
} from "semantic-ui-react";
import {
  NavLink,
  Link,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import logo from "./images/lambda-phi-logo.png";
import { LoginInfo, APILogout } from "./Utils/Login";

interface MenuProps extends RouteComponentProps {
  loginInfo: LoginInfo;
  setLoginInfo: (input: LoginInfo) => void;
}

export default withRouter(LambdaPhiMenu);

function LambdaPhiMenu(props: MenuProps): JSX.Element {
  function handleLogout(
    event: SyntheticEvent,
    dropdownProps: DropdownItemProps
  ) {
    event.preventDefault();
    APILogout((statusCode: number): void => {
      props.setLoginInfo({} as LoginInfo);
    });
  }

  var map = new Map([
    ["/mailinglists", "Mailing Lists"],
    ["/alumnidb", "Alumni DB"],
    ["/photos", "Photo Gallery"],
    ["/wiki", "Wiki"],
    ["/ordering", "Ordering & Donations"],
    ["/2021campaign", "2021 Campaign"],
    ["/contact", "Contact Us"],
    ["/account", "My Account"],
  ]);

  let menuText = "Home";
  for (let entry of Array.from(map.entries())) {
    let key = entry[0];
    let value = entry[1];

    if (props.location.pathname.startsWith(key)) {
      menuText = value;
    }
  }

  return (
    <Menu inverted>
      <Container>
        <Dropdown item text={menuText}>
          <Dropdown.Menu>
            {props.loginInfo.id ? (
              <>
                <Dropdown.Item disabled>
                  <div>Logged in as:</div>
                  <div>
                    {props.loginInfo.first_name} {props.loginInfo.last_name}{" "}
                    {props.loginInfo.chapter} {props.loginInfo.year}
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider></Dropdown.Divider>
              </>
            ) : (
              <></>
            )}
            <Dropdown.Item as={NavLink} to="/" exact>
              Home
            </Dropdown.Item>
            {props.loginInfo.id ? (
              <>
                <Dropdown.Item as={NavLink} to="/mailinglists">
                  Mailing Lists
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/alumnidb">
                  Alumni DB
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/photos">
                  Photo Gallery
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/wiki">
                  Wiki
                </Dropdown.Item>
              </>
            ) : (
              <></>
            )}
            <Dropdown.Item as={NavLink} to="/contact">
              Contact Us
            </Dropdown.Item>
            {props.loginInfo.id ? (
              <>
                <Dropdown.Divider></Dropdown.Divider>
                <Dropdown.Item as={NavLink} to="/account">
                  My Account
                </Dropdown.Item>
                <Dropdown.Divider></Dropdown.Divider>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </>
            ) : (
              <></>
            )}
          </Dropdown.Menu>
        </Dropdown>

        <Menu.Item header as={Link} to="/">
          <Image src={logo} />
        </Menu.Item>
      </Container>
    </Menu>
  );
}

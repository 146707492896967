import React from "react";

import { Container, Divider } from "semantic-ui-react";

var Footer = () => (
  <Container>
    <Divider />
    (c) Lambda Phi Corporation {new Date().getFullYear()}
  </Container>
);

export default Footer;

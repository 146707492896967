import React, { useState, useEffect } from "react";
import { Placeholder } from "semantic-ui-react";
import { GetUserInfo, UserInfo } from "../Utils/User";

interface UserNameProps {
  id: number;
}

export function UserName(props: UserNameProps) {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [userInfo, setUserInfo] = React.useState({} as UserInfo);

  useEffect(() => {
    if (loading) {
      GetUserInfo(props.id, (statusCode: number, input: UserInfo) => {
        if (statusCode === 200) {
          setUserInfo(input);
          setLoading(false);
          return;
        }

        // error
        setErrorMessage("An error occurred loading page.");
        setLoading(false);
      });
    }
  }, [loading, props.id]);

  if (loading) {
    return (
      <Placeholder>
        <Placeholder.Line length="short" />
      </Placeholder>
    );
  }

  return (
    <>
      {userInfo.first_name} {userInfo.last_name} {userInfo.chapter}{" "}
      {userInfo.year}
    </>
  );
}

import axios, { AxiosResponse, AxiosError } from "axios";
import { APIError } from "./API";

export function GetPaymentIntent(
  amount: string,
  callback: (statusCode: number, clientSecret: string) => void
) {
  axios({
    method: "get",
    url: "/api/stripe/get_donation_intent/" + amount,
  })
    .then(function (response: AxiosResponse) {
      callback(response.status, response.data.clientSecret as string);
    })
    .catch(function (error: AxiosError) {
      callback(error.response?.status || 0, "");
    });
}

export function GetPaymentIntentPromise(amount: string): Promise<string> {
  return axios({
    method: "get",
    url: "/api/stripe/get_donation_intent/" + amount,
  }).then(function (response: AxiosResponse) {
    if (response.status === 200) {
      return response.data.clientSecret;
    } else {
      return Promise.reject({
        status: response.status,
        message: "payment API error",
        debug:
          "received unexpected status from stripe/get_donation_intent: " +
          response.status,
      } as APIError);
    }
  });
}

export function GetPublishableSecretPromise(): Promise<string> {
  return axios({
    method: "get",
    url: "/api/stripe/get_key",
  }).then(function (response: AxiosResponse) {
    if (response.status === 200) {
      return response.data.key;
    } else {
      return Promise.reject({
        status: response.status,
        message: "payment API error",
        debug:
          "received unexpected status from stripe/get_key: " + response.status,
      } as APIError);
    }
  });
}

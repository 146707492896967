import axios, { AxiosResponse, AxiosError } from "axios";

export interface LoginInfo {
  // check if this is set to determine whether the user is logged in
  id: number;

  // login metadata
  legacy_base_url: string; // base URL for legacy site without trailing slash, ie https://www.lambdaphi.org

  first_name: string;
  last_name: string;
  year: number;
  chapter: string;
  InitiationNumber: number;
  MITAlumniID: string;
  Enabled: boolean;
  PledgeClassID: number;
  BigBrotherID: number;
  FamilyID: number;
}

export function EmptyLoginInfo(): LoginInfo {
  let result = {} as LoginInfo;

  return result;
}

export function APIGetCurrentLogin(
  callback: (statusCode: number, loggedin: boolean, input: LoginInfo) => void
) {
  axios({
    method: "get",
    url: "/api/get_current_login",
  })
    .then(function (response: AxiosResponse) {
      callback(
        response.status,
        response.data.loggedin,
        response.data.userinfo as LoginInfo
      );
    })
    .catch(function (error: AxiosError) {
      callback(error.response?.status || 0, false, {} as LoginInfo);
    });
}

export function APILogin(
  email: string,
  password: string,
  callback: (statusCode: number, input: LoginInfo) => void
) {
  axios({
    method: "post",
    url: "/api/login",
    data: {
      email: email,
      password: password,
    },
  })
    .then(function (response: AxiosResponse) {
      callback(response.status, response.data.userinfo as LoginInfo);
    })
    .catch(function (error: AxiosError) {
      callback(error.response?.status || 0, {} as LoginInfo);
    });
}

export function APILogout(callback: (statusCode: number) => void) {
  axios({
    method: "post",
    url: "/api/logout",
  })
    .then(function (response: AxiosResponse) {
      callback(response.status);
    })
    .catch(function (error: AxiosError) {
      callback(error.response?.status || 0);
    });
}

export function APIGetSSOKey(
  callback: (statusCode: number, key: string) => void
) {
  axios({
    method: "post",
    url: "/api/login/get_sso_key",
  })
    .then(function (response: AxiosResponse) {
      callback(response.status, response.data.key);
    })
    .catch(function (error: AxiosError) {
      callback(error.response?.status || 0, "");
    });
}

export function APIRequestPasswordReset(
  email: string,
  callback: (statusCode: number) => void
) {
  axios({
    method: "post",
    url: "/api/login/send_password_reset_email",
    data: {
      email: email,
    },
  })
    .then(function (response: AxiosResponse) {
      callback(response.status);
    })
    .catch(function (error: AxiosError) {
      callback(error.response?.status || 0);
    });
}

export function APIResetPassword(
  id: number,
  hash: string,
  password: string,
  callback: (statusCode: number) => void
) {
  axios({
    method: "post",
    url: "/api/login/reset_password",
    data: {
      id: id,
      hash: hash,
      password: password,
    },
  })
    .then(function (response: AxiosResponse) {
      callback(response.status);
    })
    .catch(function (error: AxiosError) {
      callback(error.response?.status || 0);
    });
}

import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Message, Placeholder } from "semantic-ui-react";
import { GetWikiPage, WikiPage, WikiParentPage } from "../Utils/Wiki";

interface WikiPageProps {
  id: number;
  setWikiPageParents: (x: WikiParentPage[]) => void;
}

export default function WikiPageComponent(props: WikiPageProps) {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [wikiPage, setWikiPage] = useState<WikiPage>();

  useEffect(() => {
    // this will rerun when props.id changes, so turn on loading indicator
    setLoading(true);

    GetWikiPage(
      props.id,
      (statusCode: number, input: WikiPage, parents: WikiParentPage[]) => {
        if (statusCode === 200) {
          setLoading(false);
          setWikiPage(input);
          props.setWikiPageParents(parents);
          return;
        }

        // error
        setLoading(false);
        setErrorMessage("An error occurred loading page.");
      }
    );

    // eslint-disable-next-line
  }, [props.id]);

  return (
    <>
      {loading ? (
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      ) : errorMessage ? (
        <Message negative>
          <div>{errorMessage}</div>
        </Message>
      ) : wikiPage === undefined ? (
        <Message negative>Page not found.</Message>
      ) : (
        <ReactMarkdown>{wikiPage.text}</ReactMarkdown>
      )}
    </>
  );
}

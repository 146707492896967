import axios, { AxiosResponse, AxiosError } from "axios";

export interface WikiPage {
  id: number;
  top_page_id: number;
  name: string;
  state: string;
  text: string;
  parent_page_id: number;
  parent_page: WikiPage;
  ip_address: string;
  author_user_id: number;
  needs_to_be_sent: boolean;
  lock_time: string;
  lock_user_id: number;
}

export interface WikiParentPage {
  id: number;
  name: string;
  parent_page_id: number;
}

export function GetWikiPage(
  pageId: number,
  callback: (
    statusCode: number,
    input: WikiPage,
    parents: WikiParentPage[]
  ) => void
) {
  axios({
    method: "get",
    url: "/api/wiki/" + pageId,
  })
    .then(function (response: AxiosResponse) {
      callback(
        response.status,
        response.data.page as WikiPage,
        response.data.parents as WikiParentPage[]
      );
    })
    .catch(function (error: AxiosError) {
      callback(
        error.response?.status || 0,
        {} as WikiPage,
        {} as WikiParentPage[]
      );
    });
}

import React, { useEffect } from "react";
//import { Button, Container, Divider, Grid, Header, Image, Menu, Segment } from 'semantic-ui-react'
import { Container, Loader } from "semantic-ui-react";
import "./fomantic/dist/semantic.css";
import "./App.css";
import { Switch, Route } from "react-router-dom";

import { LoginInfo, EmptyLoginInfo, APIGetCurrentLogin } from "./Utils/Login";
import Menu from "./Menu";
import Login, { LoginHelp, ResetPassword } from "./Pages/Login";
import Footer from "./Footer";
import Home from "./Pages/Home";
import ContactUs from "./Pages/ContactUs";
import StyleDemo from "./StyleDemo";
import LegacyFrame, { LegacyPageWrapper } from "./Components/LegacyFrame";
import PrivateRoute from "./Components/PrivateRoute";
import Campaign from "./Pages/Campaign";
import Wiki, { NewWiki } from "./Pages/Wiki";

export default function App() {
  const [loginInfo, setLoginInfo] = React.useState(EmptyLoginInfo());
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (loading) {
      APIGetCurrentLogin(
        (statusCode: number, loggedin: boolean, input: LoginInfo): void => {
          if (statusCode === 200 && loggedin) {
            setLoginInfo(input);
          }
          // just ignore any non-200 response

          setLoading(false);
        }
      );
    }
  }, [loading]);

  let mainContent = (
    <Switch>
      <PrivateRoute loginInfo={loginInfo} path="/mailinglists">
        <LegacyFrame route="/lists.php" loginInfo={loginInfo} />
      </PrivateRoute>
      <PrivateRoute loginInfo={loginInfo} path="/alumnidb">
        <LegacyFrame route="/alumnidb.php" loginInfo={loginInfo} />
      </PrivateRoute>
      <PrivateRoute loginInfo={loginInfo} path="/alumnidb.php">
        <LegacyPageWrapper loginInfo={loginInfo} />
      </PrivateRoute>
      <PrivateRoute loginInfo={loginInfo} path="/wiki.php">
        <LegacyPageWrapper loginInfo={loginInfo} />
      </PrivateRoute>
      <PrivateRoute loginInfo={loginInfo} path="/photos">
        <LegacyFrame route="/wiki.php?page=421" loginInfo={loginInfo} />
      </PrivateRoute>
      <PrivateRoute loginInfo={loginInfo} path="/newwiki">
        <NewWiki loginInfo={loginInfo} />
      </PrivateRoute>
      <PrivateRoute loginInfo={loginInfo} path="/wiki">
        <Wiki loginInfo={loginInfo} />
      </PrivateRoute>
      <PrivateRoute loginInfo={loginInfo} path="/wiki.php">
        <LegacyPageWrapper loginInfo={loginInfo} />
      </PrivateRoute>
      <PrivateRoute loginInfo={loginInfo} path="/ordering">
        <LegacyFrame route="/ordering.php" loginInfo={loginInfo} />
      </PrivateRoute>
      <PrivateRoute loginInfo={loginInfo} path="/unsubscribe.php">
        <LegacyPageWrapper loginInfo={loginInfo} />
      </PrivateRoute>
      <Route path="/contact">
        <ContactUs />
      </Route>
      <Route path="/help">
        <LoginHelp />
      </Route>
      <Route path="/resetpassword">
        <ResetPassword />
      </Route>{" "}
      <Route path="/2021campaign">
        <Campaign loginInfo={loginInfo} />
      </Route>
      <Route path="/styles">
        <StyleDemo />
      </Route>
      <PrivateRoute loginInfo={loginInfo} path="/account">
        <LegacyFrame
          route="/useradmin.php?cmd=editmine"
          loginInfo={loginInfo}
        />
      </PrivateRoute>
      <PrivateRoute loginInfo={loginInfo} path="/news/new">
        <LegacyFrame route="/news.php?cmd=addnews" loginInfo={loginInfo} />
      </PrivateRoute>
      <PrivateRoute loginInfo={loginInfo} path="/news/history">
        <LegacyFrame route="/news.php?cmd=archive" loginInfo={loginInfo} />
      </PrivateRoute>
      {/*  bare "/" must always be the last path, since matching is by prefix */}
      <Route path="/">
        {loginInfo.id ? <Home /> : <Login setLoginInfo={setLoginInfo} />}
      </Route>
    </Switch>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Menu loginInfo={loginInfo} setLoginInfo={setLoginInfo} />
      <Container style={{ flex: 1 }}>
        {loading ? <Loader active /> : mainContent}
      </Container>
      <Footer />
    </div>
  );
}

import { useState } from "react";
import { Link, Redirect, Route, Switch, useParams } from "react-router-dom";
import { Breadcrumb, Menu, Segment } from "semantic-ui-react";
import LegacyFrame from "../Components/LegacyFrame";
import { LoginInfo } from "../Utils/Login";
import WikiPage from "../Components/WikiPage";
import { WikiParentPage } from "../Utils/Wiki";

interface WikiProps {
  loginInfo: LoginInfo;
}

export default function Wiki(props: WikiProps) {
  return (
    <Switch>
      <Route path={`/wiki/:pageId`}>
        <WikiWithID loginInfo={props.loginInfo} />
      </Route>
      <Route path={`/wiki`}>
        <LegacyFrame route="/wiki.php" loginInfo={props.loginInfo} />
      </Route>
    </Switch>
  );
}

export function NewWiki(props: WikiProps) {
  return (
    <Switch>
      <Route path={`/newwiki/:pageId`}>
        <NewWikiWithID loginInfo={props.loginInfo} />
      </Route>
      <Route path={`/newwiki/`}>
        <Redirect to="/newwiki/1" />
      </Route>
    </Switch>
  );
}

interface WikiWithIDParamTypes {
  pageId: string;
}
function WikiWithID(props: WikiProps) {
  let { pageId } = useParams<WikiWithIDParamTypes>();
  return (
    <LegacyFrame
      route={"/wiki.php?page=" + pageId}
      loginInfo={props.loginInfo}
    />
  );
}

function NewWikiWithID(props: WikiProps) {
  let { pageId } = useParams<WikiWithIDParamTypes>();
  let pageIdNumber = parseInt(pageId);

  const [wikiPageParents, setWikiPageParents] = useState<WikiParentPage[]>();

  return (
    <div>
      <Breadcrumb size="big">
        {wikiPageParents !== undefined ? (
          wikiPageParents.map((value: WikiParentPage, index: number) => (
            <>
              {index + 1 === wikiPageParents.length ? (
                <Breadcrumb.Section key={value.id} active>
                  {value.name}
                </Breadcrumb.Section>
              ) : (
                <>
                  <Link to={"/newwiki/" + value.id}>
                    <Breadcrumb.Section link key={value.id} page_id={value.id}>
                      {value.name}
                    </Breadcrumb.Section>
                  </Link>
                  <Breadcrumb.Divider icon="right angle" />
                </>
              )}
            </>
          ))
        ) : (
          <></>
        )}
      </Breadcrumb>
      <Menu pointing>
        <Menu.Item key="view" name="view" active={true} />
        <Menu.Item key="edit" name="edit" active={false} />
        <Menu.Item key="attatch" name="attach" active={false} />
        <Menu.Item key="manage" name="manage" active={false} />
      </Menu>
      <Segment>
        <WikiPage
          id={pageIdNumber}
          setWikiPageParents={setWikiPageParents}
        ></WikiPage>
      </Segment>
    </div>
  );
}

import axios, { AxiosResponse, AxiosError } from "axios";

export interface UserInfo {
  id: number;
  first_name: string;
  last_name: string;
  initiation_number: number;
  mit_alumni_id: string;
  enabled: boolean;
  year: number;
  chapter: string;
  pledge_class_id: number;
  big_brother_user_id: number;
  family_id: number;
}

export function GetUserInfo(
  id: number,
  callback: (statusCode: number, input: UserInfo) => void
) {
  axios({
    method: "get",
    url: "/api/user/" + id,
  })
    .then(function (response: AxiosResponse) {
      callback(response.status, response.data.user as UserInfo);
    })
    .catch(function (error: AxiosError) {
      callback(error.response?.status || 0, {} as UserInfo);
    });
}
